
import { mapState } from 'vuex'

import CarouselContainer from '@/components/Carousel/CarouselContainer/CarouselContainer'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import RightSidebarSeoModal from '@/components/Modals/SidebarModal/RightSidebarSeoModal'

export default {
  name: 'CountersIndex',
  components: {
    CarouselContainer,
    MainButton,
    RightSidebarSeoModal,
  },
  props: {
    elementTest: { type: String, default: '' },
  },
  data() {
    return {
      newProduct: {
        isSidebarOpen: false,
        content: this.$t('product.newProductBlock'),
      },
    }
  },
  computed: {
    ...mapState('dynamicContent', {
      co2: state => state.counters.co2,
      products: state => state.counters.products,
    }),
  },
  beforeMount() {
    this.$root.$on('closeRightSidebar', () => {
      // Auto close when no selection
      this.closeRightSidebarModal()
    })
    this.$root.$on('openNewProductSidebar', () => {
      this.openNewProductSidebar()
    })
  },
  beforeDestroy() {
    this.$root.$off('closeRightSidebar')
    this.$root.$off('openNewProductSidebar')
  },
  methods: {
    closeRightSidebarModal() {
      this.newProduct.isSidebarOpen = false
    },

    openNewProductSidebar() {
      this.newProduct.isSidebarOpen = true
      this.newProduct.content = this.$t('product.newProductBlock', {
        co2Saved: this.co2.toLocaleString(),
        productQuantity: this.products.toLocaleString(),
      })
    },
  },
}
