
import CarouselContainer from '@/components/Carousel/CarouselContainer/CarouselContainer'
import Trustpilot from '@/mixins/trustpilot-mixin'

export default {
  name: 'TrustpilotCarousel',
  components: {
    CarouselContainer,
  },
  mixins: [Trustpilot],
  props: {
    elementTest: { type: String, default: 'trustpilot-carousel' },
  },
}
