
import CarouselContainer from '@/components/Carousel/CarouselContainer/CarouselContainer'

import Brand from '@/models/Brand'

export default {
  name: 'FeaturedBrandsCarousel',
  components: {
    CarouselContainer,
  },
  props: {
    title: { type: String, default: null },
    brands: { type: Array, default: () => [] },
    elementTest: { type: String, default: 'featured-brands' },
  },
  methods: {
    imageForBrand(brand) {
      if (brand instanceof Brand) {
        return brand.logo_url
      } else if (brand && brand.image_url) {
        return brand.image_url
      } else if (brand && brand.image) {
        return brand.image.filename
      }
      return ''
    },
  },
}
