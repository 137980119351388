
import MainButton from '@/components/Buttons/MainButton/MainButton'
import LinkResolver from '@/components/LinkResolver/LinkResolver'
export default {
  name: 'SbNewsCard',
  components: {
    MainButton,
    LinkResolver,
  },
  props: {
    news: { type: Object, default: () => {} },
    testId: { type: String, default: 'news' },
    index: { type: Number, default: 0 },
  },
}
