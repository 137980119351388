
import CarouselContainer from '@/components/Carousel/CarouselContainer/CarouselContainer'
import FeaturedCarouselItem from '@/components/Carousel/FeaturedCarouselItem/FeaturedCarouselItem'

export default {
  name: 'FeaturedSellersCarousel',
  components: {
    CarouselContainer,
    FeaturedCarouselItem,
  },
  props: {
    stories: { type: Array, default: () => [] },
    elementTest: { type: String, default: '' },
  },
}
