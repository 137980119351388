
import LinkResolver from '@/components/LinkResolver/LinkResolver'
import Mobile from '@/mixins/mobile-mixin'

export default {
  name: 'FullwidthBanner',
  components: { LinkResolver },
  mixins: [Mobile],
  provide() {
    return {
      lazy: this.lazy,
    }
  },
  props: {
    banner: { type: Object, default: () => {} },
    lazy: { type: Boolean, default: true },
  },
  computed: {
    title() {
      // Richtext if object from SB, else string
      if (this.banner?.title) {
        if (typeof this.banner?.title === 'object') {
          return this.$storyapi.richTextResolver.render(this.banner.title)
        } else if (typeof this.banner?.title === 'string') {
          return this.banner.title
        }
      }
      return null
    },
    subtitle() {
      // Richtext if object from SB, else string
      if (this.banner?.subtitle) {
        if (typeof this.banner?.subtitle === 'object') {
          return this.$storyapi.richTextResolver.render(this.banner.subtitle)
        } else if (typeof this.banner?.subtitle === 'string') {
          return this.banner.subtitle
        }
      }
      return null
    },
    bannerImage() {
      if (this.isSm && this.banner.image_mobile_url) {
        return this.banner.image_mobile_url
      } else if (this.isSm && this.banner.image_mobile?.filename) {
        return this.banner.image_mobile.filename
      } else if (this.banner.image_url) {
        return this.banner.image_url
      } else {
        return this.banner.image.filename
      }
    },
  },
}
