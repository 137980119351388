
import URL from '@/mixins/url-mixin'

export default {
  name: 'LinkResolver',
  mixins: [URL],
  props: {
    link: { type: String, default: '' },
    ariaLabel: { type: String, default: '' },
  },
}
