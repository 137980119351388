
import Mobile from '@/mixins/mobile-mixin'

export default {
  name: 'ProductsCarousel',
  components: {
    CarouselContainer: () => import('@/components/Carousel/CarouselContainer/CarouselContainer'),
    ReferenceCell: () => import('@/components/Reference/ReferenceCell/ReferenceCell'),
    FeaturedProductsCardItem: () => import('@/components/Carousel/FeaturedProductsCarousel/FeaturedProductsCardItem'),
  },
  mixins: [Mobile],
  provide() {
    return {
      lazy: this.lazy,
    }
  },
  props: {
    references: { type: Array, default: () => [] },
    count: { type: Number, default: null },
    horizontalCell: { type: Boolean, default: false },
    featuredCell: { type: Boolean, default: false },
    link: { type: String, default: '' },
    translateKey: { type: String, default: '' },
    lazy: { type: Boolean, default: true },
    fastAddToCart: { type: Boolean, default: false },
    displayButton: { type: Boolean, default: true },
    labels: { type: Object, default: () => null },
    elementTest: { type: String, default: '' },
  },
  computed: {
    buttonText() {
      return this.$te(`home.${this.translateKey}.button`) ? this.$t(`home.${this.translateKey}.button`) : null
    },
  },
}
