
import CarouselContainer from '@/components/Carousel/CarouselContainer/CarouselContainer'
import SbNewsCard from '@/components/storyblok/SbNewsCard'

export default {
  name: 'NewsCarousel',
  components: {
    CarouselContainer,
    SbNewsCard,
  },
  props: {
    news: { type: Array, default: () => [] },
    elementTest: { type: String, default: 'news' },
  },
}
