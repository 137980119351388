
import MainButton from '@/components/Buttons/MainButton/MainButton'
import LinkResolver from '@/components/LinkResolver/LinkResolver'
import { debounce } from '@/utils'

export default {
  name: 'CarouselContainer',
  components: {
    MainButton,
    LinkResolver,
  },
  props: {
    title: { type: String, default: '' },
    count: { type: Number, default: null },
    subtitle: { type: String, default: '' },
    linkLabel: { type: String, default: null },
    linkSrc: { type: String, default: null },
    elementParent: { type: String, default: '' },
    itemWidth: { type: [String, Number], default: 0 },
    itemHeight: { type: [String, Number], default: 0 },
    icon: { type: String, default: '' },
    displayButton: { type: Boolean, default: true },
    testId: { type: String, default: '' },
  },
  data() {
    return {
      carouselEl: null,
      positionScroll: 0,
      wrapperScrollWidth: 0,
    }
  },
  mounted() {
    this.carouselEl = this.elementParent ? document.querySelector(`#${this.elementParent} .carousel`) : null
    if (this.carouselEl) {
      this.carouselEl.addEventListener('scroll', debounce(this.positionScrollHandler, 300), { passive: true })
      window.addEventListener('resize', debounce(this.handlePositionIconRight, 300), { passive: true })
      this.handlePositionIconRight()
      this.wrapperScrollWidth = this.carouselEl.scrollWidth - this.carouselEl.offsetWidth
    }
  },
  beforeDestroy() {
    if (this.carouselEl) {
      this.carouselEl.removeEventListener('scroll', this.positionScrollHandler)
      window.removeEventListener('resize', this.handlePositionIconRight)
    }
    this.carouselEl = null
  },
  methods: {
    carouselScrollTo(moveTo) {
      const scrollWidthToLeft = this.carouselEl.offsetWidth - this.positionScroll
      const scrollWidthToRight = this.carouselEl.offsetWidth + this.positionScroll
      this.carouselEl.scrollTo({
        top: 0,
        left: moveTo === 'toRight' ? scrollWidthToRight : -scrollWidthToLeft,
        behavior: 'smooth',
      })
    },
    positionScrollHandler() {
      this.positionScroll = this.carouselEl.scrollLeft
    },
    handlePositionIconRight() {
      let iconRight = document.getElementById(this.elementParent + 'CarouselIconRight')
      if (iconRight && this.carouselEl) iconRight.style.left = this.carouselEl.offsetWidth + 'px'
    },
  },
}
