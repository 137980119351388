
import Category from '@/models/Category'
import CarouselContainer from '@/components/Carousel/CarouselContainer/CarouselContainer'
import FeaturedCarouselItem from '@/components/Carousel/FeaturedCarouselItem/FeaturedCarouselItem'
import i18nMx from '@/mixins/i18n-mixin'

export default {
  name: 'FeaturedSeasonalSelectionsCarousel',
  components: {
    CarouselContainer,
    FeaturedCarouselItem,
  },
  mixins: [i18nMx],
  props: {
    stories: { type: Array, default: () => [] },
    mainStory: { type: Object, default: () => {} },
    elementTest: { type: String, default: '' },
  },
  computed: {
    allCategories() {
      const categories = this.$store.state.categories.available
      const newCategories = []

      for (let i = 0; i < categories.length; i++) {
        newCategories.push(new Category(categories[i], this.country))
      }

      return newCategories
    },
    filteredStories() {
      const activeStories = []
      for (const story of this.stories) {
        if (this.activeStory(story)) {
          activeStories.push(story)
        }
      }
      return activeStories
    },
  },
  methods: {
    // Not working well, deactivate for now
    activeStory() {
      // (story) in param
      // let category = null
      // if (story?.url?.includes('/category')) {
      //   let storyCategory = story?.url?.split('/')?.pop()?.split('?')?.shift()
      //   category = this.$store.getters['categories/availableCategoryBySlug'](storyCategory)
      //   return !!category && !!((category.mkp_products_count || category.productsCount) >= 1)
      // }
      return true
    },
  },
}
