
export default {
  name: 'FeaturedCarouselItem',
  components: {
    MainButton: () => import('@/components/Buttons/MainButton/MainButton'),
    LinkResolver: () => import('@/components/LinkResolver/LinkResolver'),
  },
  props: {
    story: { type: Object, default: null },
    mainItem: { type: Boolean, default: false },
    showDescription: { type: Boolean, default: true },
    itemWidth: { type: [String, Number], default: null },
    itemHeight: { type: [String, Number], default: null },
    testId: { type: String, default: '' },
    index: { type: Number, default: 0 },
  },
  data() {
    return {
      hovered: false,
    }
  },
}
