
export default {
  name: 'SbContent',
  components: {
    LdTitle: () => import('@/components/LandingDownload/LdTitle'),
    LdSubtitle: () => import('@/components/LandingDownload/LdSubtitle'),
    LdFeaturesSection: () => import('@/components/LandingDownload/LdFeaturesSection'),
    LdDownloadBanner: () => import('@/components/LandingDownload/LdDownloadBanner'),
    LdNetworkSection: () => import('@/components/LandingDownload/LdNetworkSection'),
    LdDemoBanner: () => import('@/components/LandingDownload/LdDemoBanner'),
    LdContactForm: () => import('@/components/LandingDownload/LdContactForm'),
    LdVideoSection: () => import('@/components/LandingDownload/LdVideoSection'),
    LdPricingPlan: () => import('@/components/LandingDownload/LdPricingPlan'),
    LdAgecSection: () => import('@/components/LandingDownload/LdAgecSection'),
    LdContainerBanner: () => import('@/components/LandingDownload/LdContainerBanner'),
    LdFullwidthBanner: () => import('@/components/LandingDownload/LdFullwidthBanner'),
    SbAdditionalServicesBanner: () => import('@/components/storyblok/SbAdditionalServicesBanner'),
    SbAdditionalServicesPage: () => import('@/components/storyblok/SbAdditionalServicesPage'),
    SbAdditionalServicesSection: () => import('@/components/storyblok/SbAdditionalServicesSection'),
    SbAgecSection: () => import('@/components/storyblok/SbAgecSection'),
    SbAvatar: () => import('@/components/storyblok/SbAvatar'),
    SbAvatarList: () => import('@/components/storyblok/SbAvatarList'),
    SbButtonsLinkHover: () => import('@/components/storyblok/SbButtonsLinkHover'),
    SbContactForm: () => import('@/components/storyblok/SbContactForm'),
    SbContainerBanner: () => import('@/components/storyblok/SbContainerBanner'),
    SbCta: () => import('@/components/storyblok/SbCta'),
    SbCtaBanner: () => import('@/components/storyblok/SbCtaBanner'),
    SbCtaGroup: () => import('@/components/storyblok/SbCtaGroup'),
    SbDemoBanner: () => import('@/components/storyblok/SbDemoBanner'),
    SbDownloadBanner: () => import('@/components/storyblok/SbDownloadBanner'),
    SbFeatureBlock: () => import('@/components/storyblok/SbFeatureBlock'),
    SbFeaturesSection: () => import('@/components/storyblok/SbFeaturesSection'),
    SbFullwidthBanner: () => import('@/components/storyblok/SbFullwidthBanner'),
    SbNetworkSection: () => import('@/components/storyblok/SbNetworkSection'),
    SbNewsCard: () => import('@/components/storyblok/SbNewsCard'),
    SbOfferDetail: () => import('@/components/storyblok/SbOfferDetail'),
    SbOffersSection: () => import('@/components/storyblok/SbOffersSection'),
    SbOffersSelectionGrid: () => import('@/components/storyblok/SbOffersSelectionGrid'),
    SbPlan: () => import('@/components/storyblok/SbPlan'),
    SbPlansGroup: () => import('@/components/storyblok/SbPlansGroup'),
    SbPricingPlan: () => import('@/components/storyblok/SbPricingPlan'),
    SbRichText: () => import('@/components/storyblok/SbRichText'),
    SbSeoContent: () => import('@/components/storyblok/SbSeoContent'),
    SbServicesResumeSection: () => import('@/components/storyblok/SbServicesResumeSection'),
    SbServicesSection: () => import('@/components/storyblok/SbServicesSection'),
    SbSubscribeModal: () => import('@/components/storyblok/SbSubscribeModal'),
    SbSubtitle: () => import('@/components/storyblok/SbSubtitle'),
    SbTestimoniesTabs: () => import('@/components/storyblok/SbTestimoniesTabs'),
    SbTitle: () => import('@/components/storyblok/SbTitle'),
    SbVideoSection: () => import('@/components/storyblok/SbVideoSection'),
    SbWhiteBookForm: () => import('@/components/storyblok/SbWhiteBookForm'),
    SbTable: () => import('@/components/storyblok/SbTable'),
    Slider: () => import('@/components/Slider/Slider'),
    ContactForm: () => import('@/components/Contact/ContactForm'),
  },
  props: {
    content: { type: Object, default: () => {} },
    imgcls: { type: Boolean, default: false },
    elementTest: { type: String, default: '' },
  },
  data() {
    return {
      cls: this.imgcls,
    }
  },
}
