
import { mapActions } from 'vuex'

import API from '@/mixins/sp-api-mixin'
import HeadMx from '@/mixins/head-mixin'
import Mobile from '@/mixins/mobile-mixin'
import i18nMixin from '@/mixins/i18n-mixin'
import dynamicContentMx from '@/mixins/dynamic-content-mixin'

import Reference from '@/models/Reference'

import FullwidthBanner from '@/components/Banners/FullwidthBanner/FullwidthBanner'
import FeaturedBrandsCarousel from '@/components/Carousel/FeaturedBrandsCarousel/FeaturedBrandsCarousel'
import FeaturedSellersCarousel from '@/components/Carousel/FeaturedSellersCarousel/FeaturedSellersCarousel'
// import FeaturedInspirationsCarousel from '@/components/Carousel/FeaturedInspirationsCarousel/FeaturedInspirationsCarousel'
import FeaturedSeasonalSelectionsCarousel from '@/components/Carousel/FeaturedSeasonalSelectionsCarousel/FeaturedSeasonalSelectionsCarousel'
import MkpAlertsConfirmModal from '@/components/MkpAlerts/MkpAlertsConfirmModal/MkpAlertsConfirmModal'
import ReinsuranceBanner from '@/components/Banners/ReinsuranceBanner/ReinsuranceBanner'
import NewsCarousel from '@/components/Carousel/NewsCarousel/NewsCarousel'
import Counters from '@/components/Counters/Counters'
import ProductsCarousel from '@/components/Carousel/ProductsCarousel'
import TrustpilotCarousel from '@/components/Carousel/TrustpilotCarousel/TrustpilotCarousel'

export default {
  name: 'MainIndex',
  auth: false,
  components: {
    FullwidthBanner,
    FeaturedBrandsCarousel,
    FeaturedSellersCarousel,
    FeaturedSeasonalSelectionsCarousel,
    MkpAlertsConfirmModal,
    ReinsuranceBanner,
    NewsCarousel,
    Counters,
    TrustpilotCarousel,
    ProductsCarousel,
  },
  mixins: [API, Mobile, i18nMixin, dynamicContentMx, HeadMx],
  layout: 'default',
  async asyncData(context) {
    try {
      const [featuredProductsResults] = await Promise.allSettled([
        API.methods.spRequest({
          req: context.app.$api.reference.featured(),
          context: context.app,
        }),
        dynamicContentMx.methods.getDynamicContent({
          $store: context.store,
          contents: [
            'homebanners',
            'news',
            'homecontent',
            'featuredseasonalselections',
            // 'featuredinspirations',
            'featuredsellers',
            process.env.STORYBLOK_ENV === 'production' ? 'FeaturedBrand' : null,
          ],
        }),
        // Split in 2 queries hack because too much drafts brands (60+) blocking banners in dev/qa since stories results are limited to 100
        process.env.STORYBLOK_ENV !== 'production'
          ? await dynamicContentMx.methods.getDynamicContent({
              $store: context.store,
              contents: ['FeaturedBrand'],
            })
          : null,
      ])
      context.store.commit('dynamicContent/setFeaturedProducts', featuredProductsResults?.value?.references || [])
    } catch (e) {
      console.error(e)
    }
  },
  data() {
    return {
      bestSellers: [],
      bestSellersCount: 0,
      newProducts: [],
      newProductsCount: 0,
      isLoading: true,
    }
  },
  head() {
    return this.homeHead()
  },
  computed: {
    slides() {
      return this.$store.getters['dynamicContent/mainSlides']
    },
    edito() {
      return this.$store.getters['dynamicContent/marketplaceEdito']
    },
    featuredProducts() {
      return this.$store.getters['dynamicContent/featuredProducts']?.map(ref => new Reference(ref, this.country))
    },
    featuredBrands() {
      return this.$store.getters['dynamicContent/featuredBrands']
    },
    featuredSellers() {
      return this.$store.getters['dynamicContent/featuredSellers']
    },
    featuredInspirations() {
      return this.$store.getters['dynamicContent/featuredInspirations']
    },
    featuredSeasonalSelections() {
      return this.$store.getters['dynamicContent/featuredSeasonalSelections']
    },
    featuredSeasonalSelectionsMenu() {
      return this.$store.getters['dynamicContent/featuredSeasonalSelectionsMenu']
    },
    homeBanner() {
      return this.$store.getters['dynamicContent/homeBanner'](this.$currentUser)
    },
    bestSellersReferences() {
      return this.bestSellers?.map(ref => new Reference(ref, this.country))
    },
    newProductsReferences() {
      return this.newProducts?.map(ref => new Reference(ref, this.country))
    },
    news() {
      return this.$store.getters['dynamicContent/news']
    },
    proSolutions() {
      return this.$store.getters['dynamicContent/homeContent']('pro-solutions')
    },
    seoContent1() {
      return this.$store.getters['dynamicContent/homeContent']('seo-content-1')
    },
    seoContent2() {
      return this.$store.getters['dynamicContent/homeContent']('seo-content-2')
    },
  },
  mounted() {
    this.isLoading = false
    this.fetchCounters()
    this.fetchCarousels()
    this.$analytics.sendScreen(this.$pageTemplate.HOMEPAGE)
    if (this.$route.query.showMkpAlertDeleteSuccess) {
      setTimeout(() => {
        this.$root.$emit('modalOpen_deleteMkpAlertModal', 'deleteMkpAlertModal')
      }, 500)
    }
  },
  methods: {
    ...mapActions('dynamicContent', ['fetchCounters']),
    async fetchCarousels() {
      const params = { perPage: 6, page: 1 }
      const [bestSellersResults, newProductsResults] = await Promise.allSettled([
        this.spRequest({
          req: this.$api.reference.bestSellers(params),
        }),
        this.spRequest({
          req: this.$api.reference.fresh(params),
        }),
      ])
      this.bestSellers = bestSellersResults?.value?.references || []
      this.bestSellersCount = bestSellersResults?.value?.total || 0
      this.newProducts = newProductsResults?.value?.references || []
      this.newProductsCount = newProductsResults?.value?.total || 0
    },
  },
}
